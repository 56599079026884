<template>
<div class="home">
  <div class="home-container">
    <img
        src="@/assets/images/brains-logo.svg"
        alt="Brains logo"
        class="home-container__logo"
    />
    <div class="content home-container__content">
        <div class="card content__card">
            <h4 class="card__tittle">Login to your account!</h4>
            <p class="card__subtittle">
                Use your credentials to continue to your account
            </p>
            <button class="card__button"
            @click="$router.push('login')">Login</button>
        </div>
        <div class="card content__card">
            <h4 class="card__tittle">I'm a new freelancer!</h4>
            <p class="card__subtittle">Enter your email and start working with us</p>
            <button class="card__button"
            @click="$router.push('sign-up')">Sign up</button>
        </div>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from '../../components/footer.vue';

export default {
  components: { Footer },
  name: 'Home',
};
</script>

<style lang="scss" scoped>
.home {
    min-height: 100vh;
}
.home-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    &__logo {
        width: 300px;
        margin: 2rem auto 2.5rem;
    }
    &__content {
        display: flex;
        width: 80%;
    }
}
.content {
    &__card {
        border: 1px none #ccc;
        border-radius: $border-radius-md;
        box-shadow: $box-shadow;
        padding: 1rem 2rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        width: 45%;
        min-height: 260px;
        margin: auto;
    }
}
.card {
    &__tittle {
        font-size: 1.5rem;
        text-align: center;
    }
    &__subtittle {
        font-size: 1rem;
        text-align: center;

    }
    &__info {
        font-size: 1rem;
        text-align: center;
        color: #007DBB;

    }
    &__button {
        @include primary-button();
    }
}
</style>
