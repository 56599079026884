<template>
  <div class="footer-container">
    <div class="left footer-container__left">
        <p class="left__info">
            2022 Brains. All Rights Reserved
        </p>
    </div>
    <div class="right footer-container__right">
        <p class="right__info">Privacy Policy</p>
        <p class="right__info">Terms of Service</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss" scoped>
.footer-container {
    display: flex;
    align-items: center;
    border: 1px none #ccc;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow;
    padding: 0 2rem;
    background-color: white;
    justify-content: space-between;
    margin-top: 3rem;
    &__right {
        display: flex;
        justify-content: space-between;
        width: 28%;
    }
}
p {
    font-size: .8rem;
}
</style>
